
import './App.css';
import './Responsive.css'
import LandingPage from './LandingPage/LandingPage';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuoteForm from './GetInTouch/QuoteForm';
function App() {
  return (

    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path='/' element={<LandingPage />}></Route>
          <Route exact path='quote-request-form' element={<QuoteForm />}></Route>
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
