import React from 'react'

const Project = () => {
    return (
        <section id="projects" className="projects py-5">
            <div className="container">
                <div className="project-images">
                    <div className="project-box">
                        <img src="/landingPageImg/wca.png" alt="" />
                    </div>
                    <div className="project-box">
                        <img src="/landingPageImg/mto.png" alt="" />
                    </div>
                    <div className="project-box">
                        <img src="/landingPageImg/iata.png" alt="" />
                    </div>
                    <div className="project-box">
                        <img src="/landingPageImg/fiata.png" alt="" />
                    </div>
                    <div className="project-box">
                        <img src="/landingPageImg/msme.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Project