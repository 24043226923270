import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import 'slick-carousel';
const Services = () => {
    useEffect(() => {
        // Initialize the slider
        $('.my-slider').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          speed: 300,
          infinite: true,
          autoplaySpeed: 5000,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
    
        // Cleanup to prevent memory leaks
        return () => {
          if ($('.my-slider').slick('getSlick')) {
            $('.my-slider').slick('unslick');
          }
        };
      }, []);
    return (
        <section id="services" className="services">
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="d-flex justify-content-center align-items-center gap-2 mb-4">
                            <svg
                                width={25}
                                height={4}
                                viewBox="0 0 25 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 2H25" stroke="#FD5523" strokeWidth={3} />
                            </svg>
                            <h6 className="mb-0"> Our Services</h6>
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                        </div>
                        <h4 className="mb-2">WE ARE SPECIALISE IN TRANSPORTATION</h4>
                        <h1>Specialist Logistics Services</h1>
                    </div>
                    <div className="wrapper">
                        <div className="my-slider">
                            <div className="relative">
                                <img src="/landingPageImg/services1.png" className="w-100" alt="" />
                                <div className="box bg-white py-3 px-3  border-blue">
                                    <div className="relative">
                                        <h4 className="mb-2">sea freight</h4>
                                        <p className="mb-4">
                                            Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                            typesetting industry.{" "}
                                        </p>
                                        <a href="#">
                                            Read More
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.47706 1L14 5.42623M14 5.42623L8.47706 10M14 5.42623L0 5.57377"
                                                    stroke="#005059"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </a>
                                        <div className="logistics-img">
                                            <img src="/landingPageImg/SHIP.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                <img src="/landingPageImg/services2.png" className="w-100" alt="" />
                                <div className="box bg-white py-3 px-3  border-blue">
                                    <div className="relative">
                                        <h4 className="mb-2">air freight</h4>
                                        <p className="mb-4">
                                            Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                            typesetting industry.{" "}
                                        </p>
                                        <a href="#">
                                            Read More
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.47706 1L14 5.42623M14 5.42623L8.47706 10M14 5.42623L0 5.57377"
                                                    stroke="#005059"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </a>
                                        <div className="logistics-img">
                                            <img src="/landingPageImg/Plane.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                <img src="/landingPageImg/services3.png" className="w-100" alt="" />
                                <div className="box bg-white py-3 px-3  border-blue">
                                    <div className="relative">
                                        <h4 className="mb-2">eXPRESS freight</h4>
                                        <p className="mb-4">
                                            Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                            typesetting industry.{" "}
                                        </p>
                                        <a href="#">
                                            Read More
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.47706 1L14 5.42623M14 5.42623L8.47706 10M14 5.42623L0 5.57377"
                                                    stroke="#005059"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </a>
                                        <div className="logistics-img">
                                            <img src="/landingPageImg/Truck.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                <img src="/landingPageImg/services1.png" className="w-100" alt="" />
                                <div className="box bg-white py-3 px-3  border-blue">
                                    <div className="relative">
                                        <h4 className="mb-2">sea freight</h4>
                                        <p className="mb-4">
                                            Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                            typesetting industry.{" "}
                                        </p>
                                        <a href="#">
                                            Read More
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.47706 1L14 5.42623M14 5.42623L8.47706 10M14 5.42623L0 5.57377"
                                                    stroke="#005059"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </a>
                                        <div className="logistics-img">
                                            <img src="/landingPageImg/SHIP.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div><img src="/landingPageImg/services1.png" class="w-100" alt=""></div>
        <div><img src="/landingPageImg/services1.png" class="w-100" alt=""></div>
        <div><img src="/landingPageImg/services1.png" class="w-100" alt=""></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Services