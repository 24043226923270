import React from 'react'

const Banner = () => {
    return (
        <section className="slider">
            <div className="carousel-wrapper">
                <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                        ></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="carousel-container">
                                <img
                                    className="h-100"
                                    src="/landingPageImg/slider-img1.png"
                                    alt="First slide"
                                />
                                <div className="carousel-caption">
                                    <h3 style={{ textTransform: 'capitalize' }}>
                                        committed to <br />
                                        deliver <span>anywhere</span>
                                    </h3>
                                    <button className="btn btn-danger">OUR SERVICES</button>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="carousel-container">
                                <img
                                    className="h-100"
                                    src="/landingPageImg/banner-2.png"
                                    alt="Second slide"
                                />
                                <div className="carousel-caption">
                                    <h3 style={{ textTransform: 'capitalize' }}>
                                        committed to <br />
                                        deliver <span>ontime</span>
                                    </h3>
                                    <button className="btn btn-danger">OUR SERVICES</button>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="carousel-container">
                                <img
                                    className="h-100"
                                    src="/landingPageImg/banner-3.png"
                                    alt="Third slide"
                                />
                                <div className="carousel-caption">
                                    <h3 style={{ textTransform: 'capitalize' }}>
                                        committed to <br />
                                        deliver <span>anytime</span>
                                    </h3>
                                    <button className="btn btn-danger">OUR SERVICES</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner