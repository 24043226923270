import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from 'yup';
const Footer = () => {
    const newsletterSchema = Yup.object().shape({
        emailid: Yup.string().email('Invalid email').required('Please Enter Email'),

    });
    const formik = useFormik({
        initialValues: {
            emailid: ""

        },
        validationSchema: newsletterSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values, "value........");
            resetForm()
        },
    });
    return (
        <footer id="footer" className="footer relative">
            <div className="footer-img">
                <img src="/landingPageImg/footer-img.png" alt="" />
            </div>
            <div>
                <div className="footer-top">
                    <img src="/landingPageImg/footer-top.png" alt="" />
                </div>
                <div className="planeDrifting">
                    <img src="/landingPageImg/planeDrifting.png" alt="" />
                </div>
                <h4 className="text-absolute">
                    efficient, safe &amp; swift logistics solution!
                </h4>
                <button type="submit" className="btn-blue">
                    contact with us{" "}
                    <svg
                        width={16}
                        height={11}
                        viewBox="0 0 16 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.47706 1.27905L14 5.7576M14 5.7576L8.47706 10.3854M14 5.7576L0 5.90689"
                            stroke="white"
                            strokeWidth="1.5"
                        />
                    </svg>
                </button>
            </div>
            <div className="">
                <div className="container pb-5 ">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-info">
                                <div>
                                    <img src="/landingPageImg/footer-logo.png" alt="" />
                                </div>
                                <p className="mt-3">
                                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard
                                    dummy text.
                                </p>
                                <div className="social-links mt-3">
                                    <a href="#" className="twitter">
                                        <i className="bx bxl-twitter" />
                                    </a>
                                    <a href="#" className="facebook">
                                        <i className="bx bxl-facebook" />
                                    </a>
                                    <a href="#" className="instagram">
                                        <i className="bx bxl-instagram" />
                                    </a>
                                    <a href="#" className="google-plus">
                                        <i className="bx bxl-skype" />
                                    </a>
                                    <a href="#" className="linkedin">
                                        <i className="bx bxl-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links ps-lg-4">
                            <div className="d-flex justify-content-start align-items-center gap-2">
                                <h4>quick links</h4>
                                <svg
                                    width={36}
                                    height={19}
                                    viewBox="0 0 36 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M29.4004 10.1678H29.1217L28.9752 10.4047L24.3214 17.9262H23.378L25.5774 10.8155L25.7777 10.1678H25.0997L20.0077 10.1678H19.7105L19.5685 10.4289L18.1278 13.0772H17.6206L18.4386 9.31945L18.4617 9.2131L18.4386 9.10675L17.6206 5.349H18.1279L19.5695 7.99744L19.7115 8.2584H20.0086L25.1007 8.2584H25.7788L25.5783 7.6106L23.3781 0.5L24.3214 0.5L28.9752 8.02148L29.1217 8.2584H29.4004L34.575 8.2584C34.817 8.2584 35.0511 8.35642 35.2254 8.53436C35.4001 8.71264 35.5 8.95661 35.5 9.2131C35.5 9.46959 35.4001 9.71355 35.2254 9.89184C35.0511 10.0698 34.817 10.1678 34.575 10.1678L29.4004 10.1678Z"
                                        fill="#FD5523"
                                        stroke="#FD5523"
                                    />
                                    <path d="M18 9H0" stroke="#FD5523" />
                                </svg>
                            </div>
                            <ul className="mt-3">
                                <li>
                                    <svg
                                        width={12}
                                        height={10}
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5.17391H10M10 5.17391L6 1M10 5.17391L6 9"
                                            stroke="#FD5523"
                                            strokeWidth="1.5"
                                        />
                                    </svg>{" "}
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <svg
                                        width={12}
                                        height={10}
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5.17391H10M10 5.17391L6 1M10 5.17391L6 9"
                                            stroke="#FD5523"
                                            strokeWidth="1.5"
                                        />
                                    </svg>{" "}
                                    <Link to="/">About us</Link>
                                </li>
                                <li>
                                    <svg
                                        width={12}
                                        height={10}
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5.17391H10M10 5.17391L6 1M10 5.17391L6 9"
                                            stroke="#FD5523"
                                            strokeWidth="1.5"
                                        />
                                    </svg>{" "}
                                    <Link to="/">Services</Link>
                                </li>
                                <li>
                                    <svg
                                        width={12}
                                        height={10}
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5.17391H10M10 5.17391L6 1M10 5.17391L6 9"
                                            stroke="#FD5523"
                                            strokeWidth="1.5"
                                        />
                                    </svg>{" "}
                                    <Link to="/">latest project</Link>
                                </li>
                                <li>
                                    <svg
                                        width={12}
                                        height={10}
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5.17391H10M10 5.17391L6 1M10 5.17391L6 9"
                                            stroke="#FD5523"
                                            strokeWidth="1.5"
                                        />
                                    </svg>{" "}
                                    <Link to="/">contact us </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <div className="d-flex justify-content-start align-items-center gap-2">
                                <h4>get in touch</h4>
                                <svg
                                    width={36}
                                    height={19}
                                    viewBox="0 0 36 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M29.4004 10.1678H29.1217L28.9752 10.4047L24.3214 17.9262H23.378L25.5774 10.8155L25.7777 10.1678H25.0997L20.0077 10.1678H19.7105L19.5685 10.4289L18.1278 13.0772H17.6206L18.4386 9.31945L18.4617 9.2131L18.4386 9.10675L17.6206 5.349H18.1279L19.5695 7.99744L19.7115 8.2584H20.0086L25.1007 8.2584H25.7788L25.5783 7.6106L23.3781 0.5L24.3214 0.5L28.9752 8.02148L29.1217 8.2584H29.4004L34.575 8.2584C34.817 8.2584 35.0511 8.35642 35.2254 8.53436C35.4001 8.71264 35.5 8.95661 35.5 9.2131C35.5 9.46959 35.4001 9.71355 35.2254 9.89184C35.0511 10.0698 34.817 10.1678 34.575 10.1678L29.4004 10.1678Z"
                                        fill="#FD5523"
                                        stroke="#FD5523"
                                    />
                                    <path d="M18 9H0" stroke="#FD5523" />
                                </svg>
                            </div>
                            <ul className="mt-3 contact-us">
                                <li className="mb-4">
                                    <svg
                                        width={18}
                                        height={18}
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 18L6 15.9L1.35 17.7C1.01667 17.8333 0.708333 17.796 0.425 17.588C0.141667 17.38 0 17.1007 0 16.75V2.75C0 2.53333 0.0626666 2.34167 0.188 2.175C0.313333 2.00833 0.484 1.88333 0.7 1.8L6 0L12 2.1L16.65 0.3C16.9833 0.166667 17.2917 0.204333 17.575 0.413C17.8583 0.621667 18 0.900667 18 1.25V15.25C18 15.4667 17.9377 15.6583 17.813 15.825C17.6883 15.9917 17.5173 16.1167 17.3 16.2L12 18ZM11 15.55V3.85L7 2.45V14.15L11 15.55ZM13 15.55L16 14.55V2.7L13 3.85V15.55ZM2 15.3L5 14.15V2.45L2 3.45V15.3Z"
                                            fill="#FD5523"
                                        />
                                    </svg>{" "}
                                    <a href="#">
                                        Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                        typesetting
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <svg
                                        width={20}
                                        height={16}
                                        viewBox="0 0 20 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196667 15.0217 0.000666667 14.5507 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196666 1.45067 0.000666667 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.805 0.98 20.0007 1.45067 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.0217 15.805 18.5507 16.0007 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V14V4Z"
                                            fill="#FD5523"
                                        />
                                    </svg>{" "}
                                    <Link to="#">demo@gmail.com</Link>
                                </li>
                                <li className="mb-4">
                                    <svg
                                        width={18}
                                        height={18}
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                                            fill="#FD5523"
                                        />
                                    </svg>{" "}
                                    <Link to="#">+91 1234-567-890 </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <div className="d-flex justify-content-start align-items-center gap-2">
                                <h4>subscribe Us</h4>
                                <svg
                                    width={36}
                                    height={19}
                                    viewBox="0 0 36 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M29.4004 10.1678H29.1217L28.9752 10.4047L24.3214 17.9262H23.378L25.5774 10.8155L25.7777 10.1678H25.0997L20.0077 10.1678H19.7105L19.5685 10.4289L18.1278 13.0772H17.6206L18.4386 9.31945L18.4617 9.2131L18.4386 9.10675L17.6206 5.349H18.1279L19.5695 7.99744L19.7115 8.2584H20.0086L25.1007 8.2584H25.7788L25.5783 7.6106L23.3781 0.5L24.3214 0.5L28.9752 8.02148L29.1217 8.2584H29.4004L34.575 8.2584C34.817 8.2584 35.0511 8.35642 35.2254 8.53436C35.4001 8.71264 35.5 8.95661 35.5 9.2131C35.5 9.46959 35.4001 9.71355 35.2254 9.89184C35.0511 10.0698 34.817 10.1678 34.575 10.1678L29.4004 10.1678Z"
                                        fill="#FD5523"
                                        stroke="#FD5523"
                                    />
                                    <path d="M18 9H0" stroke="#FD5523" />
                                </svg>
                            </div>
                            <p className="mt-3 mb-3">
                                {" "}
                                Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                typesetting
                            </p>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-3">

                                <input
                                    type="email"
                                    name="emailid"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.emailid}
                                    className="w-100 "
                                    placeholder="Enter your email address"
                                />
                                {formik.touched.emailid && formik.errors.emailid ? (
                                    <div className="error">{formik.errors.emailid}</div>
                                ) : null}
                                </div>
                                <button type="submit" className="btn-orange">
                                    Subscribe{" "}
                                    <svg
                                        width={16}
                                        height={11}
                                        viewBox="0 0 16 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.47706 1.27905L14 5.7576M14 5.7576L8.47706 10.3854M14 5.7576L0 5.90689"
                                            stroke="white"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="boredr-top-custom">
                                <h3 className="mb-0">
                                    copyright 2024 committed cargo. all right reserved
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>

    )
}

export default Footer