import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { requestQuoteSchema } from '../ValidationSchema';
const WhyChooseUs = () => {
    const [startDate, setStartDate] = useState(new Date());
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            distance: '',
            load1: '',
            load2: '',
        },
        validationSchema: requestQuoteSchema,
        onSubmit: (values) => {
            console.log('Form values:', values);
        },
    });
    return (
        <section className="request-quote">
            <div className="container py-5">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-4">
                            <svg
                                width={25}
                                height={4}
                                viewBox="0 0 25 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 2H25" stroke="#FD5523" strokeWidth={3} />
                            </svg>
                            <h6 className="mb-0 text-white"> WHY CHOOSE WE</h6>
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                        </div>
                        <h2 className="mb-4 text-white text-capitalize ">
                            efficient, safe &amp; swift
                            <br /> logistics{" "}
                            <span className="text-orange" style={{ color: "#FD5523" }}>
                                {" "}
                                solution!
                            </span>
                        </h2>
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                            <p className="mb-0">
                                Lorem Ipsum&nbsp;is simply dummy text of the printing .
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                            <p className="mb-0">
                                Lorem Ipsum&nbsp;is simply dummy text of the printing .
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                            <p className="mb-0">
                                Lorem Ipsum&nbsp;is simply dummy text of the printing .
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                            <p className="mb-0">
                                Lorem Ipsum&nbsp;is simply dummy text of the printing .
                            </p>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                    fill="#FD5523"
                                    stroke="#FD5523"
                                />
                            </svg>
                            <p className="mb-0">
                                Lorem Ipsum&nbsp;is simply dummy text of the printing .
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 margin-minus relative">
                        <div className="form-box">
                            <h2>Request a Quote</h2>
                            <form className="mt-4" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative">
                                                <input
                                                    type="email"
                                                    placeholder="name "
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    value={formik?.values?.name}
                                                    onChange={formik?.handleChange}
                                                    onBlur={formik?.handleBlur}
                                                    pattern="[a-zA-Z\s]*"
                                                    onKeyPress={(event) => {
                                                        if (!/[a-zA-Z\s]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <svg
                                                    width={24}
                                                    height={25}
                                                    viewBox="0 0 24 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 12.2647C14.21 12.2647 16 10.4536 16 8.21745C16 5.98132 14.21 4.17017 12 4.17017C9.79 4.17017 8 5.98132 8 8.21745C8 10.4536 9.79 12.2647 12 12.2647ZM12 14.2884C9.33 14.2884 4 15.6442 4 18.3357V20.3593H20V18.3357C20 15.6442 14.67 14.2884 12 14.2884Z"
                                                        fill="#757575"
                                                    />
                                                </svg>
                                            </div>
                                            {formik?.touched.name && formik?.errors.name ? (
                                                <div className="error">{formik?.errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative">
                                                <input
                                                    placeholder="email "
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    value={formik?.values?.email}
                                                    onChange={formik?.handleChange}
                                                    onBlur={formik?.handleBlur}

                                                />
                                                <svg
                                                    width={24}
                                                    height={25}
                                                    viewBox="0 0 24 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M22 6.19381C22 5.0808 21.1 4.17017 20 4.17017H4C2.9 4.17017 2 5.0808 2 6.19381V18.3357C2 19.4487 2.9 20.3593 4 20.3593H20C21.1 20.3593 22 19.4487 22 18.3357V6.19381ZM20 6.19381L12 11.2529L4 6.19381H20ZM20 18.3357H4V8.21745L12 13.2765L20 8.21745V18.3357Z"
                                                        fill="#757575"
                                                    />
                                                </svg>
                                            </div>
                                            {formik?.touched.email && formik?.errors.email ? (
                                                <div className="error">{formik?.errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative">
                                                <input
                                                    placeholder="Phone "
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    value={formik?.values?.phone}
                                                    onChange={formik?.handleChange}
                                                    onBlur={formik?.handleBlur}
                                                    pattern="[a-zA-Z\s]*"
                                                    onKeyPress={(event) => {
                                                        if (!/[a-zA-Z\s]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <svg
                                                    width={18}
                                                    height={20}
                                                    viewBox="0 0 18 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.95 19.1158C14.8667 19.1158 12.8083 18.6565 10.775 17.7377C8.74167 16.819 6.89167 15.5161 5.225 13.8291C3.55833 12.142 2.271 10.2702 1.363 8.21348C0.455 6.15679 0.000666667 4.07412 0 1.96549C0 1.66194 0.0999999 1.40899 0.3 1.20662C0.5 1.00426 0.75 0.903076 1.05 0.903076H5.1C5.33333 0.903076 5.54167 0.983347 5.725 1.14389C5.90833 1.30443 6.01667 1.49398 6.05 1.71253L6.7 5.2539C6.73333 5.52372 6.725 5.75138 6.675 5.93688C6.625 6.12238 6.53333 6.28259 6.4 6.4175L3.975 8.89646C4.30833 9.52041 4.704 10.1231 5.162 10.7046C5.62 11.286 6.12433 11.8469 6.675 12.3872C7.19167 12.91 7.73333 13.395 8.3 13.8422C8.86667 14.2895 9.46667 14.6982 10.1 15.0686L12.45 12.6908C12.6 12.539 12.796 12.4253 13.038 12.3498C13.28 12.2743 13.5173 12.253 13.75 12.2861L17.2 12.9943C17.4333 13.0618 17.625 13.1842 17.775 13.3616C17.925 13.539 18 13.737 18 13.9556V18.0534C18 18.357 17.9 18.6099 17.7 18.8123C17.5 19.0147 17.25 19.1158 16.95 19.1158Z"
                                                        fill="#757575"
                                                    />
                                                </svg>
                                            </div>
                                            {formik?.touched.phone && formik?.errors.phone ? (
                                                <div className="error">{formik?.errors.phone}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative custom-date-picker">
                                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                                                {/* <input
                                                    placeholder="Date "
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                /> */}
                                                <svg
                                                    width={24}
                                                    height={26}
                                                    viewBox="0 0 24 26"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_38_2580)">
                                                        <path
                                                            d="M21.5001 4.91492H19.3334V6.26401H21.3334V21.104H2.66676V6.26401H4.66676V4.91492H2.50009C2.34425 4.91755 2.19045 4.95123 2.04747 5.01401C1.90449 5.0768 1.77514 5.16747 1.6668 5.28085C1.55846 5.39423 1.47325 5.52809 1.41605 5.6748C1.35885 5.8215 1.33077 5.97816 1.33342 6.13585V21.2322C1.33077 21.3899 1.35885 21.5466 1.41605 21.6933C1.47325 21.84 1.55846 21.9738 1.6668 22.0872C1.77514 22.2006 1.90449 22.2913 2.04747 22.354C2.19045 22.4168 2.34425 22.4505 2.50009 22.4531H21.5001C21.6559 22.4505 21.8097 22.4168 21.9527 22.354C22.0957 22.2913 22.225 22.2006 22.3334 22.0872C22.4417 21.9738 22.5269 21.84 22.5841 21.6933C22.6413 21.5466 22.6694 21.3899 22.6668 21.2322V6.13585C22.6694 5.97816 22.6413 5.8215 22.5841 5.6748C22.5269 5.52809 22.4417 5.39423 22.3334 5.28085C22.225 5.16747 22.0957 5.0768 21.9527 5.01401C21.8097 4.95123 21.6559 4.91755 21.5001 4.91492Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M5.33325 10.3113H6.66659V11.6604H5.33325V10.3113Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M9.33325 10.3113H10.6666V11.6604H9.33325V10.3113Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M13.3333 10.3113H14.6666V11.6604H13.3333V10.3113Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M17.3333 10.3113H18.6666V11.6604H17.3333V10.3113Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M5.33325 13.6841H6.66659V15.0332H5.33325V13.6841Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M9.33325 13.6841H10.6666V15.0332H9.33325V13.6841Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M13.3333 13.6841H14.6666V15.0332H13.3333V13.6841Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M17.3333 13.6841H18.6666V15.0332H17.3333V13.6841Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M5.33325 17.0568H6.66659V18.4059H5.33325V17.0568Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M9.33325 17.0568H10.6666V18.4059H9.33325V17.0568Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M13.3333 17.0568H14.6666V18.4059H13.3333V17.0568Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M17.3333 17.0568H18.6666V18.4059H17.3333V17.0568Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M6.66667 7.61317C6.84348 7.61317 7.01305 7.5421 7.13807 7.4156C7.2631 7.2891 7.33333 7.11753 7.33333 6.93863V2.89134C7.33333 2.71244 7.2631 2.54087 7.13807 2.41437C7.01305 2.28786 6.84348 2.2168 6.66667 2.2168C6.48986 2.2168 6.32029 2.28786 6.19526 2.41437C6.07024 2.54087 6 2.71244 6 2.89134V6.93863C6 7.11753 6.07024 7.2891 6.19526 7.4156C6.32029 7.5421 6.48986 7.61317 6.66667 7.61317Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M17.3334 7.61317C17.5102 7.61317 17.6798 7.5421 17.8048 7.4156C17.9298 7.2891 18.0001 7.11753 18.0001 6.93863V2.89134C18.0001 2.71244 17.9298 2.54087 17.8048 2.41437C17.6798 2.28786 17.5102 2.2168 17.3334 2.2168C17.1566 2.2168 16.987 2.28786 16.862 2.41437C16.737 2.54087 16.6667 2.71244 16.6667 2.89134V6.93863C16.6667 7.11753 16.737 7.2891 16.862 7.4156C16.987 7.5421 17.1566 7.61317 17.3334 7.61317Z"
                                                            fill="#757575"
                                                        />
                                                        <path
                                                            d="M8.66675 4.91492H15.3334V6.26401H8.66675V4.91492Z"
                                                            fill="#757575"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_38_2580">
                                                            <rect
                                                                width={24}
                                                                height="24.2837"
                                                                fill="white"
                                                                transform="translate(0 0.867676)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <label htmlFor="">Distance By(killo)</label>
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <div className="relative">
                                                <div className="circle">
                                                    <img src="/landingPageImg/circle.png" alt="" />
                                                </div>
                                                <div className="progress-bar w-100">
                                                    <div className="progress" style={{ width: "60%" }} />
                                                    <div className="circle" style={{ left: "60%" }} />
                                                    <div className="arrow" style={{ left: "60%" }} />
                                                </div>
                                                <div className="plane-img">
                                                    <svg
                                                        width={19}
                                                        height={19}
                                                        viewBox="0 0 19 19"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12.4004 10.1678H12.1217L11.9752 10.4047L7.3214 17.9262H6.37802L8.57737 10.8155L8.77772 10.1678H8.0997L3.0077 10.1678H2.7105L2.56848 10.4289L1.1278 13.0772H0.620552L1.43856 9.31945L1.46171 9.2131L1.43856 9.10675L0.620552 5.349H1.12789L2.56949 7.99744L2.71154 8.2584H3.00865L8.10065 8.2584H8.77875L8.57831 7.6106L6.3781 0.5L7.3214 0.5L11.9752 8.02148L12.1217 8.2584H12.4004L17.575 8.2584C17.817 8.2584 18.0511 8.35642 18.2254 8.53436C18.4001 8.71264 18.5 8.95661 18.5 9.2131C18.5 9.46959 18.4001 9.71355 18.2254 9.89184C18.0511 10.0698 17.817 10.1678 17.575 10.1678L12.4004 10.1678Z"
                                                            fill="#FD5523"
                                                            stroke="#FD5523"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="email"
                                                placeholder="0-300"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                value={formik?.values?.distance}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                                pattern="[a-zA-Z\s]*"
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>
                                    {formik?.touched.distance && formik?.errors.distance ? (
                                        <div className="error">{formik?.errors.distance}</div>
                                    ) : null}
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    {...formik.getFieldProps('load1')}
                                                >
                                                    <option value="">Load</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                {formik.touched.load1 && formik.errors.load1 ? (
                                                    <div className="error">{formik.errors.load1}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-3">
                                            <div className="relative">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    {...formik.getFieldProps('load2')}
                                                >
                                                    <option value="">Load</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                {formik.touched.load2 && formik.errors.load2 ? (
                                                    <div className="error">{formik.errors.load2}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn-orange">
                                    CONTACT US{" "}
                                    <svg
                                        width={16}
                                        height={11}
                                        viewBox="0 0 16 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.47706 1.27905L14 5.7576M14 5.7576L8.47706 10.3854M14 5.7576L0 5.90689"
                                            stroke="white"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                </button>
                            </form>
                        </div>
                        {/* <div className="absolute">
                            <img src="/landingPageImg/img.png" alt="" />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default WhyChooseUs