import React from 'react'

const Testimonial = () => {
    return (
        <section className="testimonial">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-7 ">
                        <div className="pe-md-5">
                            <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                                <svg
                                    width={25}
                                    height={4}
                                    viewBox="0 0 25 4"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M0 2H25" stroke="#FD5523" strokeWidth={3} />
                                </svg>
                                <h6 className="mb-0" style={{ textTransform: "capitalize" }}>
                                    client Testimonials{" "}
                                </h6>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.053 10.6289H12.7757L12.6289 10.8641L7.72271 18.7227H6.67983L9.00329 11.2779L9.20583 10.6289H8.526L3.166 10.6289H2.87008L2.72769 10.8883L1.20408 13.6641H0.621742L1.48836 9.7186L1.51192 9.61133L1.48836 9.50406L0.621742 5.55859H1.20417L2.72875 8.33445L2.87116 8.59375H3.167L8.527 8.59375H9.20691L9.00428 7.94474L6.67991 0.5L7.72271 0.5L12.6289 8.35854L12.7757 8.59375H13.053L18.5 8.59375C18.7633 8.59375 19.0171 8.69953 19.2051 8.88973C19.3932 9.08012 19.5 9.33958 19.5 9.61133C19.5 9.88307 19.3932 10.1425 19.2051 10.3329C19.0171 10.5231 18.7633 10.6289 18.5 10.6289L13.053 10.6289Z"
                                        fill="#FD5523"
                                        stroke="#FD5523"
                                    />
                                </svg>
                            </div>
                            <h2 className="mb-3">
                                what our Customers
                                <br /> say <span className="text-orange">about us</span>
                            </h2>
                            <div className="card testimonial-card relative mt-5">
                                <div className="card-body py-4 px-5 text-center relative">
                                    <div className="d-flex align-items-center justify-content-start gap-3">
                                        <div className="icon">
                                            <img src="/landingPageImg/testimonial.png" />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                                justifyContent: "start"
                                            }}
                                        >
                                            <h6 className="mb-0" style={{ textTransform: "capitalize" }}>
                                                john doe
                                            </h6>
                                            <div>
                                                <img src="/landingPageImg/star.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">
                                        Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text.
                                    </p>
                                    <div className="Waves-img">
                                        <img src="/landingPageImg/Waves.png" alt="" />
                                    </div>
                                </div>
                                <div className="testimonial-vector">
                                    <img src="/landingPageImg/vector.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-img">
                            <img src="/landingPageImg/testimonials.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-lg-block d-none">
                            <img
                                src="/landingPageImg/testimonial-img.png"
                                alt=""
                                style={{ width: "-webkit-fill-available" }}
                            />
                        </div>
                        <div className="mt-5">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="relative">
                                        <img
                                            className="client-1"
                                            src="/landingPageImg/client-1.png"
                                            alt=""
                                        />
                                        <img
                                            className="client-2"
                                            src="/landingPageImg/client-2.png"
                                            alt=""
                                        />
                                        <img
                                            className="client-3"
                                            src="/landingPageImg/client-3.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="client-review mt-2">
                                        <h6 className="mb-0" style={{ textTransform: "capitalize" }}>
                                            customer satisfied
                                        </h6>
                                        <p className="mb-0">4.8 (15k Reviews)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Testimonial