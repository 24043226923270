import * as Yup from "yup";
export const uploadImageSchema = Yup.object().shape({
  images: Yup.mixed().notRequired('A file is required'),
});
export const validationCreateServiceSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter full name"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone Number must contain exactly 10 digits")
    .required("Please enter your mobile number"),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter your email address."),
  origin: Yup.string().required("Please enter origin "),
  destination: Yup.string().required("Please enter destination"),
  product_name: Yup.string().required("Please enter product name"),
  product_type_id: Yup.string().required("Please select product type"),
  shipment_type_id: Yup.string().required("Please select shipment type"),
  shipment_mode_id: Yup.string().required("Please select shipment mode"),
  shipment_via_id: Yup.string().required("Please select shipment via"),
  cargo_type_id: Yup.string().required("Please select cargo type"),
  required_servie_id: Yup.string().required("Please select service"),
  expected_transit_date: Yup.date().required("Please enter expected transit time"),
  detailed_requirement: Yup.string().required("Please enter detailed requirement"),
  agreement: Yup.number()
    .oneOf([1], 'You must accept the terms and conditions')
    .required('Agreement is required'),
  package_info: Yup.array().of(
    Yup.object({
      package_type_id: Yup.string().required("Please select package type"),
      length: Yup.string()
        .required('Please enter dimensions')
        .matches(/^\d+$/, 'Length must be a number'),
      width: Yup.string().when('length', {
        is: (length) => length,
        then: Yup.string()
          .required('Width is required')
          .matches(/^\d+$/, 'Width must be a number'),
      }),
      height: Yup.string().when(['length', 'width'], {
        is: (length, width) => length && width,
        then: Yup.string()
          .required('Height is required')
          .matches(/^\d+$/, 'Height must be a number'),
      }),
      width: Yup.number().required("Please enter dimensions "),
      height: Yup.number().required("Please enter dimensions "),
      // dimensions: Yup.object().shape({
      //   length: Yup.string(),
      //   width: Yup.string(),
      //   height: Yup.string(),
      // }).test('dimensions', 'Please enter dimensions', function(value) {
      //   const { length, width, height } = value;
      //   return length && width && height;
      // }),
      max_weight: Yup.number().required("Please enter max weight"),
      max_weight_unit_id: Yup.string().required("Please select max weight unit"),
      gross_weight: Yup.number().required("Please enter gross weight"),
      gross_weight_unit_id: Yup.number().required("Please enter gross weight unit"),
      total_boxes: Yup.number().required("Please enter quantity"),
      
    })
  ).notRequired()
});
export const uploadVideoSchema = Yup.object().shape({
  images: Yup.mixed().notRequired('A video is required'),
});

export const requestQuoteSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  distance: Yup.number().min(0, 'Distance must be at least 0').max(300, 'Distance must be less than 300').required('Distance is required'),
  load1: Yup.string().required('Load selection is required'),
  load2: Yup.string().required('Load selection is required'),
})