import React from 'react'
import Header from './Header';
import Banner from './Banner';
import Freight from './Freight';
import AboutUs from './AboutUs';
import Services from './Services';
import WhyChooseUs from './WhyChooseUs';
import Testimonial from './Testimonial';
import Project from './Project';
import Footer from './Footer';
const LandingPage = () => {
    return (
        <>
        <Header/>
        <Banner/>
        <Freight/>
        <AboutUs/>
        <Services/>
        <WhyChooseUs/>
        <Testimonial/>
        <Project/>
        <Footer/>
        </>
    )
}

export default LandingPage